import React from 'react';
// import React, { useState, useEffect } from 'react';
// import axiosInstance from "../../utils/axiosInstance";
// import sendBlack from '../../asset/comments/send_black.svg';
// import sendWhite from '../../asset/comments/send_white.svg';
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
import { jwtDecode } from 'jwt-decode';

function Google() {
    // const [googleEmail, setGoogleEmail] = useState('');

    // const googleLoginFn = async () => {
    //     let prmData = {
    //         username: googleEmail,
    //     };
    //     try {
    //         const response = await axiosInstance.post('CompanyUser/select', prmData);
    //     } catch (error) {
    //         console.error('There was an error making the request!', error);
    //     }
    // };
    const handleSuccess = (response) => {
        const credential = response.credential;  // The ID token is in response.credential
        const decoded = jwtDecode(credential);   // Decode the token to get user info

        console.log('User Info:', decoded);

        // Display user details
        alert(`Hello, ${decoded.name}\nEmail: ${decoded.email}`);
    };

    const handleFailure = (error) => {
        console.error('Login Failed:', error);
    };

    return (
        <div className="loginCard registrationCard">
            {/* <input type="text" className="inputField" placeholder="Google ID" onChange={(e) => setGoogleEmail(e.target.value)} /> */}
            <GoogleOAuthProvider clientId="421756867538-j0u7v4he4hojeb98q1o2f2h0kcscgi2a.apps.googleusercontent.com">
                <div>
                    <GoogleLogin
                        onSuccess={handleSuccess}
                        onError={handleFailure}
                    />
                </div>
            </GoogleOAuthProvider>
            {/* <div className="flex justify-between items-center">
                <h5 className="font-300"></h5>
                <div onClick={googleLoginFn}>
                    <img src={sendWhite} alt="" className="darkMode" />
                    <img src={sendBlack} alt="" className="lightMode" />
                </div>
            </div> */}
        </div>
    );
}

export default Google;
