import { useContext } from 'react';
import { GlobalContext } from './GlobalContext'; // Ensure correct path and import

export const usePersona = () => {
  const context = useContext(GlobalContext);
  
  // Check if context is defined to avoid errors
  if (!context) {
    throw new Error('usePersona must be used within a GlobalProvider');
  }

  const { personaDetails, loading, error } = context;
  return { personaDetails, loading, error };
};

export const usePost = () => {
  const context = useContext(GlobalContext);

  // Check if context is defined to avoid errors
  if (!context) {
    throw new Error('usePost must be used within a GlobalProvider');
  }

  const { postDetails, loading, error } = context;
  return { postDetails, loading, error };
};

export const usePostOfPersona = () => {
  const context = useContext(GlobalContext);

  // Check if context is defined to avoid errors
  if (!context) {
    throw new Error('usePostOfPersona must be used within a GlobalProvider');
  }

  const { postDetailsOfPersona, loading, error } = context;
  return { postDetailsOfPersona, loading, error };
};

export const useMyBunch = () => {
  const context = useContext(GlobalContext);
  
  // Check if context is defined to avoid errors
  if (!context) {
    throw new Error('useMyBunch must be used within a GlobalProvider');
  }

  const { myBunchDetails, loading, error } = context;
  return { myBunchDetails, loading, error };
};