import React, { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import axiosInstance from "../utils/axiosInstance";
import { useAuth } from '../components/context/AuthContext/AuthContext';
import { useMixin } from '../components/context/MixinContext/useMixin';
import { useVariable } from '../components/context/VariableContext/VariableContext';
import { GlobalContext } from '../components/context/PersonaPostContext/GlobalContext';
import { usePost } from '../components/context/PersonaPostContext/useGlobal';
import { useNavigate } from 'react-router-dom';
import CommentSection from '../components/comments/CommentSection.js';
import BubbleChart from '../components/BubbleChart';

import profilePic from '../asset/header/dp.png';
import commentBlack from '../asset/comments/comment_black.svg';
import commentWhite from '../asset/comments/comment_white.svg';
import unratedBlack from '../asset/unrated_black.svg';
import unratedWhite from '../asset/unrated_white.svg';
import sendBlack from '../asset/comments/send_black.svg';
import sendWhite from '../asset/comments/send_white.svg';
// 
import intelligent from '../asset/smallGraph/intelligent.svg';
import stupid from '../asset/smallGraph/stupid.svg';
import helpless from '../asset/smallGraph/helpless.svg';
import bandit from '../asset/smallGraph/bandit.svg';
import ineffective from '../asset/smallGraph/ineffective.svg';

function PostDetails() {
    const { isAuthenticated, userDetails } = useAuth();
    const { state, updateState } = useVariable();
    const { postId } = useParams();
    const { postDetails, loading: loadingPersona, error: errorPersona } = usePost();
    const { setPostId, setCall } = useContext(GlobalContext);
    const [commentField, setCommentField] = useState('');
    const [commentSectionKey, setCommentSectionKey] = useState(0);
    const [quadSelected, setQuadSelected] = useState('');
    const [showRatings, setShowRatings] = useState(false);
    const { showLoginPopup, setShowLoginPopup, setPopupHeading } = useMixin();
    const [sendData, setSendData] = useState([]);
    const [count, setCount] = useState(0);

    const addComment = async () => {
        if (isAuthenticated) {
            let prmData = {
                typeOfInsertTagUniqueId: state.commentInsert,
                postComments: commentField,
                postUniqueId: parseInt(postId),
                postCommentParentId: null
            };
            let test = {
                award_status: false,
                comments: commentField,
                no_of_likes: null,
                no_of_replies: 0,
                post_comment_unique_id: 0,
                post_unique_id: parseInt(postId),
                time_ago: "just now",
                user_image: null,
                user_name: userDetails.user_name,
                user_unique_id: userDetails.user_unique_id
            };
            setCount(1);
            try {
                // setCount(prevCount => prevCount + 1);
                setCommentField('');
                setCommentSectionKey(prevKey => prevKey + 1);
                setCall(prevState => !prevState);
                setSendData(test);
                const response = await axiosInstance.post('userAction/userActionInsert', prmData);
            } catch (error) {
                console.error('There was an error making the request!', error);
                // setCount(prevCount => prevCount - 1);
            }
        }
        else {
            toggleLoginPopup('Login to comment');
        }
    };

    const addVote = (type) => {
        if (isAuthenticated) {
            var society = 0;
            var self = 0;
            var vote = 0;
            setQuadSelected(type);

            switch (type) {
                case 'intelligent':
                    society = 1;
                    self = 1;
                    vote = 7;
                    break;
                case 'stupid':
                    society = -1;
                    self = -1;
                    vote = 11;
                    break;
                case 'bandit':
                    society = -1;
                    self = 1;
                    vote = 12;
                    break;
                case 'helpless':
                    society = 1;
                    self = -1;
                    vote = 13;
                    break;
                default:
                    society = 0;
                    self = 0;
                    vote = 0;
                    break;
            }
            let prmData = {
                typeOfInsertTagUniqueId: state.voteInsert,
                postVoteSociety: society,
                postVoteSelf: self,
                postVoteTypeTagUniqueId: vote,
                postUniqueId: postId
            };
            try {
                const response = axiosInstance.post('userAction/userActionInsert', prmData);
                setCall(prevState => !prevState);
                alert("voted to" + " " + type + " " + "successfully!");
            } catch (error) {
                console.error('There was an error making the request!', error);
            }
        }
        else {
            toggleLoginPopup('Login to vote');
        }
    }

    const toggleLoginPopup = (message) => {
        setShowLoginPopup(!showLoginPopup);
        setPopupHeading(message);
    };

    const navigate = useNavigate();
    const handleNavigation = () => {
        navigate(`/persona_details/${postDetails[0].personauniqueid}`);
    }

    useEffect(() => {
        setPostId(postId);
    }, [setPostId]);

    return (
        <div className='flex justify-center'>
            {postDetails.length > 0 &&
                <div className='flex flex-col gap-[10px] rounded-[5px] lg:rounded-[0px] w-full lg:w-1/2 bg-white dark:bg-black'>
                    <div className='postCard flex flex-col gap-1 border-black rounded-[5px]'>
                        <div className='flex justify-end items-center gap-1'>
                            <div>
                                {(postDetails[0].user_name == null || postDetails[0].user_name == '') &&
                                    <h5 className='font-400 text-right'>anonymous</h5>
                                }
                                {(postDetails[0].user_name != null || postDetails[0].user_name != '') &&
                                    <h5 className='font-400 text-right'>{postDetails[0].user_name}</h5>
                                }
                                <h6 className='font-100 text-right'>{postDetails[0].time_ago}</h6>
                            </div>
                            <div>
                                {postDetails[0].user_image ?
                                    <img src={`${process.env.REACT_APP_IMAGE_URL}${postDetails[0].user_image}`} alt="" />
                                    :
                                    <img src={profilePic} alt="" />
                                }
                            </div>
                        </div>
                        <div className='flex gap-[10px]'>
                            <img src={`${process.env.REACT_APP_IMAGE_URL}${postDetails[0].post_image}`} alt="..." className='w-[30px] h-[40px]' onClick={handleNavigation} />
                            <h3 className='font-400'>{postDetails[0].post_name}</h3>
                        </div>
                        <div className='dividerBorder rounded-[10px] flex justify-between items-center'>
                            <div className='flex items-center gap-1'>
                                <img src={commentWhite} alt="" className="darkMode" />
                                <img src={commentBlack} alt="" className="lightMode" />
                                <h6 className='font-100'>{postDetails[0].no_of_comments + count} comments</h6>
                            </div>
                            <div className='flex items-center gap-1'>
                                <h6 className='font-100'>{postDetails[0].no_of_votes} votes</h6>
                                {postDetails[0].vote_type == 'intelligent' &&
                                    <img src={intelligent} alt="" />
                                }
                                {postDetails[0].vote_type == 'stupid' &&
                                    <img src={stupid} alt="" />
                                }
                                {postDetails[0].vote_type == 'helpless' &&
                                    <img src={helpless} alt="" />
                                }
                                {postDetails[0].vote_type == 'bandit' &&
                                    <img src={bandit} alt="" />
                                }
                                {postDetails[0].vote_type == 'ineffective' &&
                                    <img src={ineffective} alt="" />
                                }
                                {postDetails[0].vote_type == 'unrated' &&
                                    <img src={ineffective} alt="" />
                                }
                                {/* <BubbleBlue data={postDetails[0].data} /> */}
                            </div>
                        </div>
                    </div>
                    <div className='quadCard p-[5px_20px] rounded-[5px] flex justify-center items-center gap-[30px]'>
                        <div>
                            {postDetails[0].self == null
                                ?
                                <div>
                                    <img src={unratedWhite} alt="" className="darkMode" />
                                    <img src={unratedBlack} alt="" className="lightMode" />
                                </div>
                                :
                                <BubbleChart data={postDetails[0].data} />
                            }
                        </div>
                        {!showRatings && <div className='flex flex-col gap-[13px]'>
                            <div>
                                <h3 className='font-100'>Probably<span className='font-900'>: {postDetails[0].vote_type}</span> </h3>
                            </div>
                            <div>
                                <h5 className='font-300'>Q Score:</h5>
                                {postDetails[0].self == null
                                    ?
                                    <h5 className='font-700'>Be the FIRST to Vote</h5>
                                    :
                                    <h5 className='font-300'>{postDetails[0].self}, {postDetails[0].society}</h5>
                                }
                            </div>
                            <div>
                                <button className='btnGray' onClick={() => setShowRatings(true)} >Vote breakdown</button>
                            </div>
                        </div>}
                        {showRatings && <div className='flex flex-col gap-[13px]'>
                            <div>
                                <div className='flex justify-between'>
                                    <h3 className=''>Intelligent</h3>
                                    <div className='flex items-end gap-1'>
                                        <h3>: {postDetails[0].sum_of_intelligent}</h3>
                                        <h5 className='font-100'>votes</h5>
                                    </div>
                                </div>
                                <div className='flex justify-between'>
                                    <h3 className=''>Helpless</h3>
                                    <div className='flex items-end gap-1'>
                                        <h3>: {postDetails[0].sum_of_helpless}</h3>
                                        <h5 className='font-100'>votes</h5>
                                    </div>
                                </div>
                                <div className='flex justify-between'>
                                    <h3 className=''>Bandit</h3>
                                    <div className='flex items-end gap-1'>
                                        <h3>: {postDetails[0].sum_of_bandit}</h3>
                                        <h5 className='font-100'>votes</h5>
                                    </div>
                                </div>
                                <div className='flex justify-between'>
                                    <h3 className=''>Stupid</h3>
                                    <div className='flex items-end gap-1'>
                                        <h3>: {postDetails[0].sum_of_stupid}</h3>
                                        <h5 className='font-100'>votes</h5>
                                    </div>
                                </div>
                            </div>
                            <div className='flex justify-center'>
                                <button className='btnGray' onClick={() => setShowRatings(false)}>Rating</button>
                            </div>
                        </div>}
                    </div>
                    {isAuthenticated &&
                        <div className='mx-[5px] px-[20px] py-[5px] flex items-center gap-[30px] rounded-[5px] voteCard'>
                            <div className='flex flex-col gap-[2px]'>
                                <div className="flex gap-[2px]">
                                    <button onClick={() => addVote('helpless')} className='w-[50px] h-[50px] rounded-[5px] flex justify-center items-center cursor-pointer bg-[#4169E180] hover:bg-[#4169E1] focus:bg-[#4169E1]'>
                                        <div className='w-[20px] h-[20px] rounded-full bg-[#EEAD2B]'> </div>
                                    </button>
                                    <button onClick={() => addVote('intelligent')} className='w-[50px] h-[50px] rounded-[5px] flex justify-center items-center bg-[#4169E180] hover:bg-[#4169E1] focus:bg-[#4169E1]'>
                                        <div className='w-[20px] h-[20px] rounded-full bg-[#6DEE2B]'> </div>
                                    </button>
                                </div>
                                <div className='flex gap-[2px]'>
                                    <button onClick={() => addVote('stupid')} className='w-[50px] h-[50px] rounded-[5px] flex justify-center items-center bg-[#4169E180] hover:bg-[#4169E1] focus:bg-[#4169E1]'>
                                        <div className='w-[20px] h-[20px] rounded-full bg-[#EE4B2B]'> </div>
                                    </button>
                                    <button onClick={() => addVote('bandit')} className='w-[50px] h-[50px] rounded-[5px] flex justify-center items-center bg-[#4169E180] hover:bg-[#4169E1] focus:bg-[#4169E1]'>
                                        <div className='w-[20px] h-[20px] rounded-full bg-[#EEAD2B]'> </div>
                                    </button>
                                </div>
                            </div>
                            {quadSelected == '' && <div>
                                <h3 className='font-900'>choose quadrant <br /> <span className='font-400'>to vote</span></h3>
                            </div>}
                            {quadSelected == 'stupid' && <div className='flex flex-col gap-[13px]'>
                                <h3 className='font-900'><span className='text-[#EE4B2B]'>Loss</span> to Society</h3>
                                <h3 className='font-900'><span className='text-[#EE4B2B]'>Loss</span> to Self</h3>
                                <div className='flex justify-center'>
                                    <button className='btnGray' onClick={() => setQuadSelected('')}>Clear</button>
                                </div>                    </div>}
                            {quadSelected == 'helpless' && <div className='flex flex-col gap-[13px]'>
                                <h3 className='font-900'><span className='text-[#6DEE2B]'>Benefits</span> to Society</h3>
                                <h3 className='font-900'><span className='text-[#EE4B2B]'>Loss</span> to Self</h3>
                                <div className='flex justify-center'>
                                    <button className='btnGray' onClick={() => setQuadSelected('')}>Clear</button>
                                </div>
                            </div>}
                            {quadSelected == 'intelligent' && <div className='flex flex-col gap-[13px]'>
                                <h3 className='font-900'><span className='text-[#6DEE2B]'>Benefits</span> to Society</h3>
                                <h3 className='font-900'><span className='text-[#6DEE2B]'>Benefits</span> to Self</h3>
                                <div className='flex justify-center'>
                                    <button className='btnGray' onClick={() => setQuadSelected('')}>Clear</button>
                                </div>
                            </div>}
                            {quadSelected == 'bandit' && <div className='flex flex-col gap-[13px]'>
                                <h3 className='font-900'><span className='text-[#EE4B2B]'>Loss</span> to Society</h3>
                                <h3 className='font-900'><span className='text-[#6DEE2B]'>Benefits</span> to Self</h3>
                                <div className='flex justify-center'>
                                    <button className='btnGray' onClick={() => setQuadSelected('')}>Clear</button>
                                </div>
                            </div>}
                        </div>
                    }
                    <div className='commentField mx-[5px] relative flex justify-start items-start'>
                        <div className='relative w-full'>
                            {isAuthenticated ?
                                <input type="text" placeholder="Add a comment" value={commentField} onChange={(e) => setCommentField(e.target.value)} className='h-[40px] w-full px-[15px] rounded-[2px] dark:bg-black' />
                                :
                                <div onClick={addComment}>
                                    <input type="text" placeholder="Login to comment" disabled className='h-[40px] w-full px-[15px] rounded-[2px] dark:bg-black' />
                                </div>
                            }
                            <div className='absolute top-0 bottom-0 right-4 m-auto inline-table' onClick={addComment}>
                                <img src={sendWhite} alt="" className="darkMode" />
                                <img src={sendBlack} alt="" className="lightMode" />
                            </div>
                        </div>
                    </div>
                    <CommentSection postId={postId} key={commentSectionKey} sendData={sendData} />
                </div>
            }
        </div>
    );
}

export default PostDetails;
