import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from "../components/context/AuthContext/AuthContext";
import axiosInstance from "../utils/axiosInstance";
import BubbleChart from '../components/BubbleChart';
import SwipeableItem from './SwipeableItem';
import { useSwipeable } from "react-swipeable";
import '../styles/swipe.css';
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

import unratedBlack from '../asset/unrated_black.svg';
import unratedWhite from '../asset/unrated_white.svg';

import trash from '../asset/trash.svg';
import share from '../asset/share.svg';

function Persona({ persona }) {
    const navigate = useNavigate();
    const { isAuthenticated, userDetails } = useAuth();
    const [showDiv, setShowDiv] = useState(false);

    const handlers = useSwipeable({
        onSwipedRight: () => setShowDiv(true),
        onSwipedLeft: () => setShowDiv(false),
        preventDefaultTouchmoveEvent: true,
        trackMouse: true
    });

    const handleNavigation = () => {
        navigate(`/persona_details/${persona.persona_unique_id}`);
    };

    const handleShare = () => {
        toast.success("Shared");
    };
    const handleDelete = () => {
        if (isAuthenticated) {
            let prmData = {
                personaUniqueId: persona.persona_unique_id,
                loguseruniqueid: userDetails.companyUserUniqueId
            };
            try {
                // const response = axiosInstance.post('persona/delete', prmData);
                toast.success("Successfully deleted");
            } catch (error) {
                console.error('There was an error making the request!', error);
            }
        }
        else {
            alert("please login to delete");
        }
    };

    const setBorder = (value) => {
        switch (value) {
            case 'intelligent':
                return 'persona intelligentBorder';
            case 'stupid':
                return 'persona stupidBorder';
            case 'helpless':
                return 'persona helplessBorder';
            case 'bandit':
                return 'persona banditBorder';
            default:
                return 'persona neutralBorder';
        }
    };

    return (
        <div className='flex'>
            <div className={setBorder(persona.vote_type)}>
                {showDiv &&
                    <div className='flex'>
                        <div className='bg-[#ee4b2b40] w-[35px] flex justify-center items-center' onClick={handleDelete}>
                            <img src={trash} alt="" className='' />
                        </div>
                        <div className='bg-[#4169e180] w-[35px] flex justify-center items-center' onClick={handleShare}>
                            <img src={share} alt="" className='' />
                        </div>
                    </div>
                }
                <div className='personCard' {...handlers}>
                    <div className='w-[40px] flex-shrink-0 mr-[14px] lg:mr-[0px]'>
                        <img src={`${process.env.REACT_APP_IMAGE_URL}${persona.persona_image}`} alt="Persona" className='w-[40px] h-[60px] rounded-[0px] lg:rounded-[40px] opacity-1 lg:opacity-[0.4]' />
                    </div>
                    <div className='flex flex-col justify-center gap-1 flex-grow' onClick={handleNavigation}>
                        <h3 className='font-400'>{persona.persona_name}</h3>
                        <h5 className='font-300'>{persona.persona_description}</h5>
                        {/* <h5 className='font-300'>{persona.fromdate} - {persona.todate}</h5> */}
                    </div>
                    <div className='w-[75px] flex-shrink-0'>
                        {persona.self == null
                            ?
                            <div>
                                <img src={unratedWhite} alt="" className="darkMode" />
                                <img src={unratedBlack} alt="" className="lightMode" />
                            </div>
                            :
                            <BubbleChart data={persona.data} type="personaComponent" />
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Persona;
