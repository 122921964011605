import React, { useState, useEffect, useContext } from 'react';
import Persona from "../components/Persona";

import { GlobalContext } from '../components/context/PersonaPostContext/GlobalContext';
import { useMyBunch } from '../components/context/PersonaPostContext/useGlobal';

function Bunch() {

  const { setBunchId } = useContext(GlobalContext);
  const { myBunchDetails, loading: loadingMyBunch, error: errorMyBunch } = useMyBunch();

  useEffect(() => {
    setBunchId(1);
  }, []);

  return (
    <div className="md:layout">
      <div className='flex flex-wrap justify-center items-start min-h-screen gap-2 lg:gap-10'>
        <div className='w-full md:w-1/4 flex flex-col gap-1 lg:gap-[10px]'>
          {myBunchDetails.length > 0 ? (
            myBunchDetails.map((persona, index) => (
              <Persona key={index} persona={persona} />
            ))
          ) : (
            <div>Your my bunch is empty</div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Bunch;
